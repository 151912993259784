export const blue = {
  color: '#254dc7',
};

export const lightgrey = {
  color: '#8d8d8d',
};

export const white = {
  color: '#ffffff',
};
